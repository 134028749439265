import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './characters.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { IAFKCharacter } from '../../modules/common/model/graphql-types';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { AFKCharacter } from '../../modules/afk/common/components/afk-character';

interface IAfkCharacterNodes {
  nodes: IAFKCharacter[];
}

interface IAfkCharacterEntry {
  allCharacters: IAfkCharacterNodes;
}

interface IProps {
  data: IAfkCharacterEntry;
}

const AFKCharacters: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'A',
            tooltip: 'A Rarity',
            image: (
              <StaticImage
                src="../../images/afk/icons/rarity_a.png"
                width={24}
                alt="A"
              />
            )
          },
          {
            value: 'S',
            tooltip: 'S Rarity',
            image: (
              <StaticImage
                src="../../images/afk/icons/rarity_s.png"
                width={24}
                alt="S"
              />
            )
          }
        ]
      },
      {
        key: 'faction',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Graveborn',
            tooltip: 'Graveborn',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_grave.png"
                width={24}
                alt="Graveborn"
              />
            )
          },
          {
            value: 'Lightbearer',
            tooltip: 'Lightbearer',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_light.png"
                width={24}
                alt="Lightbearer"
              />
            )
          },
          {
            value: 'Mauler',
            tooltip: 'Mauler',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_mauler.png"
                width={24}
                alt="Mauler"
              />
            )
          },
          {
            value: 'Wilder',
            tooltip: 'Wilder',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_wilder.png"
                width={24}
                alt="Wilder"
              />
            )
          },
          {
            value: 'Celestial',
            tooltip: 'Celestial',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_cele.png"
                width={24}
                alt="Celestial"
              />
            )
          },
          {
            value: 'Hypogean',
            tooltip: 'Hypogean',
            image: (
              <StaticImage
                src="../../images/afk/icons/faction_hypo.png"
                width={24}
                alt="Hypogean"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Mage',
            tooltip: 'Mage',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_mage.png"
                width={24}
                alt="Mage"
              />
            )
          },
          {
            value: 'Marksman',
            tooltip: 'Marksman',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_mark.png"
                width={24}
                alt="Marksman"
              />
            )
          },
          {
            value: 'Rogue',
            tooltip: 'Rogue',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_rogue.png"
                width={24}
                alt="Rogue"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_support.png"
                width={24}
                alt="Support"
              />
            )
          },
          {
            value: 'Tank',
            tooltip: 'Tank',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_tank.png"
                width={24}
                alt="Tank"
              />
            )
          },
          {
            value: 'Warrior',
            tooltip: 'Warrior',
            image: (
              <StaticImage
                src="../../images/afk/icons/class_warrior.png"
                width={24}
                alt="Warrior"
              />
            )
          }
        ]
      },
      {
        key: 'damage',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Magic',
            tooltip: 'Magic',
            image: (
              <StaticImage
                src="../../images/afk/icons/damage_magic.png"
                width={24}
                alt="Magic"
              />
            )
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/afk/icons/damage_phys.png"
                width={24}
                alt="Physical"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.damage && activeFilters.damage !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.damageType.toLowerCase() === activeFilters.damage.toLowerCase()
        );
      }
      if (activeFilters.range && activeFilters.range !== 'all') {
        filtered = filtered.filter((emp) => emp.range === activeFilters.range);
      }
      if (activeFilters.faction && activeFilters.faction !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.faction &&
            emp.faction.length > 0 &&
            emp.faction.indexOf(activeFilters.faction) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke characters-afk'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/afk/categories/category_characters.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Characters List</h1>
          <h2>All characters available in AFK Journey!</h2>
          <p>
            Last updated: <strong>03/04/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Characters"
        subtitle={
          <span className="additional-text">
            Showing {visibleCharacters.length} Character
            {visibleCharacters.length !== 1 && 's'}
          </span>
        }
      />
      <div className="employees-filter-bar afk">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className={`employees-container afk-cards`}>
        {visibleCharacters.map((emp, index) => {
          return (
            <span key={index}>
              <Card className="avatar-card">
                <AFKCharacter
                  slug={emp.slug}
                  mode="card"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </span>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage src="../../images/afk/sad.webp" alt="No results" />
          <p>No characters found. Try changing your filter. </p>
        </div>
      )}
      <br /> <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default AFKCharacters;

export const Head: React.FC = () => (
  <Seo
    title="Characters List | AFK Journey | Prydwen Institute"
    description="All characters available in AFK Journey!"
    game="afk"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulAfkCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        rarity
        class
        faction
        damageType
        range
        ratings {
          afk
          pvp
          dream_overall
          dream_yeti
          dream_orson
          dream_crystal
          dream_wolf
          dream_brute
          dream_creeper
          dream_illucia
          dream_thal
        }
      }
    }
  }
`;
